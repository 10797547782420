import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";



const firebaseConfig = {
  apiKey: "AIzaSyCCed-NorI-W2LfHXDUmT3GpLe1NNfHIYk",
  authDomain: "xhado-27320.firebaseapp.com",
  projectId: "xhado-27320",
  storageBucket: "xhado-27320.appspot.com",
  messagingSenderId: "300751991686",
  appId: "1:300751991686:web:000cb97b6c6e88e13a3207",
  measurementId: "G-ZLZMJRNF5J"
};

const app = initializeApp(firebaseConfig);
const authentication = getAuth();

export {app, authentication};
